@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;600;800;900&display=swap');

html {
    background-color: #f3f4f7;
}
* {
    font-family: 'Raleway', sans-serif;
}

body {
    background:linear-gradient(#f3f4f7 0,#f4f5f7 100%) no-repeat left center !important;
}

/* .p-selectbutton {
    width: 100% !important;
} */
/* .p-selectbutton .p-button.p-component {
    width: 50%;
} */
.p-button{
    background-color: #4b1584;
    border-color: #4b1584;
}
.p-button:hover{
    background-color: #621cae !important;
    border-color: #621cae;
}
.p-highlight {
    /* background: #4b1584 !important; */
    border-color: #4b1584 !important;
}
/* .p-tabmenu .p-tabmenu-nav{
    background: transparent!important;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
    border-color: #621cae;
    color: #621cae;
    background-color: #fff;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    background-color: transparent;
}
.p-highlight{
    background: transparent !important;
} */
.p-tabview .p-tabview-nav{

}
.p-tabview-title{
    margin-right: 10px;
}
.class-border-less{
    border-width: 0px !important;
    border: 0px solid #ccc !important;
}
.nextui-table-container{
    border:0px !important;
}
.nextui-input-label--left{
    width: 180px !important;
}
.nextui-checkbox-text{
    font-size: 16px;
    color:#8a90a2;
    display: inline;
}
.nextui-checkbox-text a {
    color:#0072f5;
    font-weight: 600;
    text-decoration: underline;
}
